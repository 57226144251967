

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { Channel } from "types/channel";
import { ChannelResponse, RequestStatus } from "types/services";
import { BaseThunkApi, RootState } from "store";
import { configApi } from "services";

type ChannelState = {
  list: Channel[];
  status: RequestStatus;
  error: SerializedError["message"];
  selected: {
    channel?: Channel;
    id?: Channel["nbaChannelId"];
    status: RequestStatus;
    error?: SerializedError["message"];
  };
};

const initialState: ChannelState = {
  list: [],
  status: "idle",
  error: undefined,
  selected: { status: "idle" },
};

const fetchChannels = createAsyncThunk("channel/fetchChannels", async () => {
  return await configApi.fetchChannelList();
});

const fetchChannelById = createAsyncThunk(
  "channel/fetchChannelById",
  async (id: Channel["nbaChannelId"]) => {
    return await configApi.fetchChannelById(id);
  }
);



const updateChannel = createAsyncThunk<ChannelResponse, Channel, BaseThunkApi>(
  "channel/updateChannel",
  async (channel: Channel, { dispatch, rejectWithValue }) => {
    try {
      const updateResponse = await configApi.updateChannel(channel);
      // always fetch updated channel list after update
      dispatch(fetchChannels());
      // ensure that updated channel is fetched next time it's viewed
      dispatch(channelsActions.setSelectedChannel({ status: "idle" }));

      return updateResponse;
    } 
    catch (e) {
      return rejectWithValue(e);
    }
  }
);


const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    // Example of a regular, non-async action - note use of PayloadAction to type payload
    // deleteConfig: (state, action: PayloadAction<number>) => {
    //   state.list = state.list.filter((i) => i.id !== action.payload);
    // },
    setSelectedChannel: (
      state,
      action: PayloadAction<ChannelState["selected"]>
    ) => {
      state.selected = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.status = "complete";
        state.list = action.payload;
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })
      .addCase(fetchChannelById.pending, (state, action) => {
        state.selected.status = "pending";
        state.selected.id = action.meta.arg;
      })
      .addCase(fetchChannelById.fulfilled, (state, action) => {
        state.selected.channel = action.payload;
        state.selected.status = "complete";
      })
      .addCase(fetchChannelById.rejected, (state, action) => {
        state.selected.status = "error";
        state.selected.error = action.error.message;
      })
  },
});

export const channelsReducer = channelsSlice.reducer;
export const channelsActions = {
  ...channelsSlice.actions,
  fetchChannels,
  fetchChannelById,
  updateChannel,
};
export const channelsSelectors = {
  channelsList: (state: RootState) => state.channels.list,
  channelById: (state: RootState, id: Channel["nbaChannelId"]) =>
    state.channels.list.find((c) => id === c.nbaChannelId),
  selectedChannel: (state: RootState) => state.channels.selected,
};

