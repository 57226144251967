import { ModelVar } from "types/support";

export const mockModelVars: ModelVar[] = [
  {
    name: "likelihoodscore",
    desc: "likelihoodscore",
    category: "continuous",
  },
  {
    name: "binarymodel",
    desc: "binarymodel",
    category: "binary",
  },
  {
    name: "categoricalmodel",
    desc: "categoricalmodel",
    category: "categorical",
  },
];
