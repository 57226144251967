import { FC } from "react";
import { PageBase } from "components";
import { Tabs, Table, PageHeader } from "antd";
import QueryBuilder, { Field } from "react-querybuilder";
import { ColumnsType } from "antd/lib/table";

type ActionChannel = {
  key: number;
  description: string;
  cost: number;
  digital: boolean;
  intel: string;
  contactRule: string;
};

type Action = {
  key: number;
  description: string;
  actionChannels: ActionChannel[];
};

const actions: Action[] = [
  {
    key: 1001,
    description: "COVID Shot",
    actionChannels: [
      {
        key: 5678,
        description: "homepage-sidney",
        cost: 0.07,
        digital: true,
        intel: "Intel A",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
      {
        key: 5679,
        description: "homepage-web",
        cost: 0.01,
        digital: true,
        intel: "Intel B",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
      {
        key: 5680,
        description: "email",
        cost: 1.22,
        digital: true,
        intel: "Intel D",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
    ],
  },
  {
    key: 1002,
    description: "Push-Ups",
    actionChannels: [
      {
        key: 5678,
        description: "homepage-sidney",
        cost: 0.07,
        digital: true,
        intel: "Intel A",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },

      {
        key: 5679,
        description: "contactpage-web",
        cost: 0.02,
        digital: false,
        intel: "Intel C",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
    ],
  },
  {
    key: 1003,
    description: "Yearly Checkup",
    actionChannels: [
      {
        key: 5678,
        description: "homepage-sidney",
        cost: 0.07,
        digital: true,
        intel: "Intel A",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
      {
        key: 5679,
        description: "homepage-web",
        cost: 0.01,
        digital: true,
        intel: "Intel B",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
      {
        key: 5680,
        description: "contactpage-web",
        cost: 0.02,
        digital: false,
        intel: "Intel C",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
      {
        key: 5681,
        description: "email",
        cost: 1.22,
        digital: true,
        intel: "Intel D",
        contactRule:
          "((facts['hasViewedIDCard'] == false)) and ((facts['hasDownloadedIDCard'] == false)) and ((facts['countDaysCvrgEffTMS'] < 90 ))",
      },
    ],
  },
];

const actionColumns: ColumnsType<Action> = [
  {
    key: "key",
    title: "ID",
    align: "center",
    dataIndex: "key",
    width: 50,
  },
  {
    key: "description",
    title: "Description",
    dataIndex: "description",
  },
];

const actionChannelColumns: ColumnsType<ActionChannel> = [
  {
    key: "channel",
    title: "Channel",
    dataIndex: "description",
  },
  {
    key: "cost",
    title: "Cost",
    dataIndex: "cost",
  },
  {
    key: "digital",
    title: "Digital",
    dataIndex: "digital",
    render: (digital: boolean) => (digital ? "Yes" : "No"),
  },
  {
    key: "intel",
    title: "Intel",
    dataIndex: "intel",
  },
];

// type ActionChannel = {
//     key: number;
//     description: string;
//     cost: number;
//     digital: boolean;
//     intel: string;
//     contactRule: string;
//   };

const { TabPane } = Tabs;
function callback(key: any) {
  console.log(key);
}

//Query Builder
const fields: Field[] = [
  { name: "firstName", label: "First Name" },
  { name: "lastName", label: "Last Name" },
  { name: "age", label: "Age" },
  { name: "address", label: "Address" },
  { name: "phone", label: "Phone" },
  { name: "email", label: "Email" },
  { name: "twitter", label: "Twitter" },
  { name: "isDev", label: "Is a Developer?", defaultValue: false },
  {
    name: "date",
    label: "Date",
    inputType: "date",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "null", label: "is null" },
      { name: "notNull", label: "is not null" },
    ],
  },
];

export const Testing: FC = (props) => {
  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   axios.get("https://jsonplaceholder.typicode.com/users/").then((res) => {
  //     setUsers(res.data);
  //   });
  // }, []);

  function logQuery(query: any) {
    console.log(query);
  }

  const expandedRowRender = (action: Action) => (
    <Table<ActionChannel>
      columns={actionChannelColumns}
      dataSource={action.actionChannels}
      pagination={false}
      expandable={{
        expandedRowRender: (actionChannel) => (
          <div>Contact Rule: {actionChannel.contactRule}</div>
        ),
        defaultExpandAllRows: true,
        expandIcon: () => "",
      }}
    />
  );

  return (
    <PageBase>
      <PageHeader title="Testing" />
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Actions" key="1">
          <Table<Action>
            columns={actionColumns}
            dataSource={actions}
            expandable={{
              expandedRowRender,
              defaultExpandAllRows: true,
              expandIcon: () => "",
            }}
            pagination={false}
            size="small"
          />
        </TabPane>
        <TabPane tab="Contact Rule" key="3">
          <QueryBuilder fields={fields} onQueryChange={logQuery} />
        </TabPane>
      </Tabs>
    </PageBase>
  );
};
