import { FactsVar } from "types/support";

export const mockFactsVariables: FactsVar[] = [
  {
    name: "isNewMember",
    desc: "isNewMember",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isNewMember']",
  },
  {
    name: "isNewPlanYear",
    desc: "isNewPlanYear",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isNewPlanYear']",
  },
  {
    name: "isActiveMember",
    desc: "isActiveMember",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isActiveMember']",
  },
  {
    name: "hasViewedIDCard",
    desc: "hasViewedIDCard",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedIDCard']",
  },
  {
    name: "hasDownloadedIDCard",
    desc: "hasDownloadedIDCard",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasDownloadedIDCard']",
  },
  {
    name: "effectiveTMS",
    desc: "effectiveTMS",
    schema: "personFacts",
    datatype: "datetime",
    expVar: "personFacts['effectiveTMS']",
  },
  {
    name: "countYTDClaims",
    desc: "countYTDClaims",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countYTDClaims']",
  },
  {
    name: "hasViewedPlanDetails",
    desc: "hasViewedPlanDetails",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedPlanDetails']",
  },
  {
    name: "isClickDWNLDSydney",
    desc: "isClickDWNLDSydney",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isClickDWNLDSydney']",
  },
  {
    name: "inKodiakList",
    desc: "inKodiakList",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['inKodiakList']",
  },
  {
    name: "hasViewedKodiakpage",
    desc: "hasViewedKodiakpage",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedKodiakpage']",
  },
  {
    name: "hasViewedMHD",
    desc: "hasViewedMHD",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedMHD']",
  },
  {
    name: "countSavedArticles",
    desc: "countSavedArticles",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countSavedArticles']",
  },
  {
    name: "countLikedArticles",
    desc: "countLikedArticles",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countLikedArticles']",
  },
  {
    name: "countViewedArticles",
    desc: "countViewedArticles",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countViewedArticles']",
  },
  {
    name: "countViewedPrograms",
    desc: "countViewedPrograms",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countViewedPrograms']",
  },
  {
    name: "countEnrolledActionPlans",
    desc: "countEnrolledActionPlans",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countEnrolledActionPlans']",
  },
  {
    name: "countSelectedInterests",
    desc: "countSelectedInterests",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countSelectedInterests']",
  },
  {
    name: "ChangedPCPInLast10days",
    desc: "ChangedPCPInLast10days",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['ChangedPCPInLast10days']",
  },
  {
    name: "countOONClaims",
    desc: "countOONClaims",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countOONClaims']",
  },
  {
    name: "hasViewedOONPage",
    desc: "hasViewedOONPage",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedOONPage']",
  },
  {
    name: "isLoginbyMobile",
    desc: "isLoginbyMobile",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isLoginbyMobile']",
  },
  {
    name: "isCAOptOut",
    desc: "isCAOptOut",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isCAOptOut']",
  },
  {
    name: "hasViewedPrfCAOptOut",
    desc: "hasViewedPrfCAOptOut",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedPrfCAOptOut']",
  },
  {
    name: "isCAReinstatement",
    desc: "isCAReinstatement",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['isCAReinstatement']",
  },
  {
    name: "hasViewedPrfCAReinstate",
    desc: "hasViewedPrfCAReinstate",
    schema: "personFacts",
    datatype: "boolean",
    expVar: "personFacts['hasViewedPrfCAReinstate']",
  },
  {
    name: "countDaysCvrgEffTMS",
    desc: "countDaysCvrgEffTMS",
    schema: "personFacts",
    datatype: "integer",
    expVar: "personFacts['countDaysCvrgEffTMS']",
  },
];
