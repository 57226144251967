import { FC } from "react";
import { Header } from "components";
import { MainNav } from "navigation";
import { Layout } from "antd";

const { Content, Sider } = Layout;

const navbarHeight = "64px";

export const PageBase: FC = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Sider
          width={313}
          style={{
            position: "sticky",
            top: navbarHeight,
            height: `calc(100vh - ${navbarHeight}`,
          }}
        >
          <MainNav />
        </Sider>
        <Layout>
          <Content
            style={{ padding: 24, minHeight: 280, backgroundColor: "white" }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
