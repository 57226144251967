import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  FormInstance,
  InputNumber,
} from "antd";
import { FC, useEffect, useState } from "react";
import { Channel } from "types/channel";
import { ConfigActionChannel } from "types/config";
import { Intel, ModelVar, RelevancyVar } from "types/support";

export type ActionChannelProps = {
  channel: Channel;
  supportVars: {
    relevancyVars: RelevancyVar[];
    intelTypes: Intel[];
    modelVars: ModelVar[];
  };
  actionChannel?: ConfigActionChannel;
  form?: ActionChannelForm;
  onFormReady?: (
    nbaChannelId: Channel["nbaChannelId"],
    form: ActionChannelForm
  ) => void;
};

export type ActionChannelFormData = Pick<
  ConfigActionChannel,
  "actionChannelRelVar" | "actionChannelIntelType" | "actionChannelModelVar"
> & { actionChannelValue: string; actionChannelWeight: string };

export type ActionChannelForm = FormInstance<ActionChannelFormData>;

const { Option } = Select;

export const ActionChannel: FC<ActionChannelProps> = ({
  channel,
  supportVars: { relevancyVars, intelTypes, modelVars },
  actionChannel,
  form: _form,
  onFormReady,
}) => {
  const [formReady] = useState(() => onFormReady);
  const [form] = Form.useForm<ActionChannelFormData>(_form);

  useEffect(() => {
    if (formReady) {
      formReady(channel.nbaChannelId, form);
    }
  }, [formReady, form, channel.nbaChannelId]);

  return (
    <Form<ActionChannelFormData>
      layout="vertical"
      form={form}
      initialValues={{
        ...actionChannel,
        ...channel,
      }}
      name={`actionChannel-${Math.random()}`}
    >
      <Row gutter={16} align="bottom">
        <Col className="gutter-row">
          <Form.Item name={"nbaChannelName"} label="ID">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item name={"nbaChannelCost"} label="Cost">
            <InputNumber disabled />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            name={"nbaChannelDigital"}
            label=""
            valuePropName="checked"
          >
            <Checkbox disabled>Digital</Checkbox>
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            name={"nbaChannelOutbound"}
            label=""
            valuePropName="checked"
          >
            <Checkbox disabled>Outbound</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="bottom">
        <Col className="gutter-row">
          <Form.Item
            name={"actionChannelValue"}
            label="Value"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input
              type="number"
              addonBefore={"$"}
              onBlur={(e) => {
                const value = e.target.valueAsNumber.toFixed(2);
                form.setFieldsValue({ actionChannelValue: value });
              }}
              style={{ width: "140px" }}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item name={"actionChannelWeight"} label="Weight">
            <Input type="number" addonAfter={"%"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            name={"actionChannelRelVar"}
            label="Relevance"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select style={{ width: "160px" }}>
              {relevancyVars.map((rv) => (
                <Option key={rv.name} value={rv.name}>
                  {rv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            name={"actionChannelIntelType"}
            label="Intel Type"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select style={{ width: "160px" }}>
              {intelTypes.map((i) => (
                <Option key={i.name} value={i.name}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            name={"actionChannelModelVar"}
            label="Model Likelihood"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select style={{ width: "160px" }}>
              {modelVars.map((mv) => (
                <Option key={mv.name} value={mv.name}>
                  {mv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
