import { Switch, Route, useHistory, Redirect } from "react-router-dom";
// import { ProtectedRoute } from "./ProtectedRoute";
import {
  PageNotFound,
  Configs,
  ConfigDetail,
  Action,
  Testing,
  Channels,
  ChannelDetail,
} from "pages";

import { FC } from "react";

export interface HistoryState {
  from?: string;
  to?: string;
}

export const useHistoryWithState = () => {
  return useHistory<HistoryState>();
};

export const RouterConfig: FC = () => (
  <Switch>
    <Route path="/" exact>
      <Redirect to="/configs" />
    </Route>
    <Route path="/configs" exact component={Configs} />
    <Route path="/configs/:id" exact component={ConfigDetail} />
    <Route path="/configs/:id/:actionId" exact component={Action} />
    <Route path="/channels" exact component={Channels} />
    <Route path="/channels/:id" exact component={ChannelDetail} />
    <Route path="/testing" exact component={Testing} />
    <Route path="*" component={PageNotFound} />
  </Switch>
);
