import { Config, Action, ConfigListItem } from "types/config";
import { Channel } from "types/channel";
import { FactsVar, Intel, ModelVar, RelevancyVar } from "types/support";

export type ConfigApi = {
  fetchConfigList: () => Promise<ConfigListItem[]>;
  fetchConfigById: (id: Config["nbaConfigId"]) => Promise<Config | undefined>;
  deleteConfig: (id: ConfigListItem["nbaConfigId"]) => Promise<void>;
  updateConfig: (config: Config) => Promise<ConfigResponse>;
  activateConfig: (id: Config["nbaConfigId"]) => Promise<void>;
  fetchChannelList: () => Promise<Channel[]>;
  fetchChannelById: (
    id: Channel["nbaChannelId"]
  ) => Promise<Channel | undefined>;
  fetchActionList: () => Promise<Action[]>;
  updateChannel: (config: Channel) => Promise<ChannelResponse>;
  fetchFactsVariables: () => Promise<FactsVar[]>;
  fetchRelevancyVariables: () => Promise<RelevancyVar[]>;
  fetchIntelTypes: () => Promise<Intel[]>;
  fetchModelVariables: () => Promise<ModelVar[]>;
};

export const REQUEST_STATUS_OPTIONS = [
  "idle",
  "pending",
  "error",
  "complete",
] as const;
export type RequestStatus = typeof REQUEST_STATUS_OPTIONS[number];

export type ConfigResponse = {
  nbaConfigId: string /* Config that is being updated/inserted. If to be inserted, id will be empty */;
  nbaExpressionError?: string /* <nbaExpressionCheckResp> If present, contains the expression issue encountered */;
  nbaChannelError?: string /* error that occurred with the Channel*/;
  nbaActionChannelError?: string /* Error that occurred with the action/channel */;
};

export type ChannelResponse = {
  nbaChannelId: string /* Channel that is being updated/inserted. If to be inserted, id will be empty */;
  //deletionFailure: string   /* Applies to nba channel delete - If present, configurations using the channelid and cannot be deliete */
};

export type ErrorType =
  | "ConfigUpdateError"
  | "ConfigActivateError"
  | "ChannelUpdateError";

export class ApiError extends Error {
  constructor(
    public errType: ErrorType,
    public innerError: any,
    ...params: ConstructorParameters<ErrorConstructor>
  ) {
    super(...params);
    this.name = this.constructor.name;
  }
}
