import { ConfigListItem } from "types/config";

export const mockConfigList: ConfigListItem[] = [
  {
    nbaConfigId: "134",
    activeFlag: false,
    name: "Enrollment Season",
    desc: "Enrollment Season",
    actionsInUse: 6,
    totalActions: 17,
    updateTS: 'a"Thu Jul 15 2020 14:06:28 GMT-0400 (Eastern Daylight Time)"a',
  },
  {
    nbaConfigId: "552",
    activeFlag: false,
    name: "California Only",
    desc: "California Only",
    actionsInUse: 0,
    totalActions: 17,
    updateTS: 'a"Thu Jul 15 2021 17:06:28 GMT-0400 (Eastern Daylight Time)"a',
  },
  {
    nbaConfigId: "36578",
    activeFlag: false,
    name: "Seniors",
    desc: "Seniors",
    actionsInUse: 12,
    totalActions: 17,
    updateTS: 'a"Thu Jul 15 2022 04:06:28 GMT-0400 (Eastern Daylight Time)"a',
  },
  {
    nbaConfigId: "43",
    activeFlag: true,
    name: "General",
    desc: "General",
    actionsInUse: 17,
    totalActions: 17,
    updateTS: 'a"Thu Jul 15 2021 11:06:28 GMT-0400 (Eastern Daylight Time)"a',
  },
];
