import { RelevancyVar } from "types/support";

export const mockRelevancyVars: RelevancyVar[] = [
  {
    name: "relevancyscore",
    desc: "relevancyscore",
    category: "binary",
  },
  {
    name: "continuousrelevancyvar",
    desc: "continuousrelevancyvar",
    category: "continuous",
  },
  {
    name: "extrarelvar",
    desc: "extrarelvar",
    category: "binary",
  },
];
