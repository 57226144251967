import { FC, ReactNode } from "react";
import { Menu, Spin } from "antd";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import Icon, {
  PartitionOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { IconBranch } from "assets";
import {
  useSelector,
  configsSelectors,
  channelsSelectors,
  actionsSelectors,
} from "store";

type MenuItem = {
  root: string;
  label: string;
  icon?: ReactNode;
  submenuLabel?: (id: string) => ReactNode;
};

export const MainNav: FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const root = `/${url.split("/")[1]}`;
  const { id, actionId } = useParams<{ id: string; actionId: string }>();
  const openKeys = [root];

  const selectedConfig = useSelector((s) =>
    configsSelectors.configListItemById(s, id)
  );
  const selectedChannel = useSelector(channelsSelectors.selectedChannel);
  const selectedAction = useSelector((s) =>
    actionsSelectors.actionById(s, actionId)
  );

  const menuItems: MenuItem[] = [
    {
      label: "Configurations",
      icon: <PartitionOutlined rotate={90} />,
      root: "/configs",
      submenuLabel: (id) =>
        id === "0"
          ? "New Configuration"
          : selectedConfig?.name ?? <Spin size="small" />,
    },
    {
      label: "Channels",
      icon: <NotificationOutlined />,
      root: "/channels",
      submenuLabel: (id) =>
        selectedChannel?.channel?.nbaChannelName ?? <Spin size="small" />,
    },
    // { label: "Testing", root: "/testing" },
  ];

  if (actionId) {
    openKeys.push(`${root}/${id}`);
  }

  const onMenuClick = (path: string) => {
    history.push(path);
  };

  return (
    <nav>
      <Menu
        expandIcon={" "}
        className={"main-nav"}
        theme={"dark"}
        selectedKeys={[url]}
        openKeys={openKeys}
        mode="inline"
      >
        {menuItems.map((i) => {
          return root === i.root && id ? (
            <Menu.SubMenu
              key={i.root}
              icon={i.icon}
              onTitleClick={() => onMenuClick(i.root)}
              title={i.label}
            >
              {actionId ? (
                <Menu.SubMenu
                  key={`${i.root}/${id}`}
                  icon={<Icon component={IconBranch} />}
                  onTitleClick={() => onMenuClick(`${i.root}/${id}`)}
                  title={i.submenuLabel ? i.submenuLabel(id) : ""}
                >
                  <Menu.Item
                    key={`${i.root}/${id}/${actionId}`}
                    icon={<Icon component={IconBranch} />}
                  >
                    {selectedAction?.desc ?? <Spin size="small" />}
                  </Menu.Item>
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={`${i.root}/${id}`}
                  icon={<Icon component={IconBranch} />}
                >
                  {i.submenuLabel ? i.submenuLabel(id) : ""}
                </Menu.Item>
              )}
            </Menu.SubMenu>
          ) : (
            <Menu.Item icon={i.icon} key={i.root}>
              <Link to={i.root}>{i.label}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </nav>
  );
};
