import { FC, CSSProperties } from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { MainLogo } from "assets";

// const UserAvatar: FC<{ user: User }> = ({ user }) => (
//   <div style={{ display: "flex", alignItems: "center" }}>
//     {user.avatarUrl ? (
//       <Avatar src={user.avatarUrl} alt={`${user.firstname}'s avatar`} />
//     ) : (
//       <UserOutlined className="hide-sm" style={{ fontSize: 20 }} />
//     )}
//     <span className="hide-sm" style={{ marginLeft: 8 }}>
//       Hi {user.firstname}
//     </span>
//   </div>
// );

const defaultStyle: CSSProperties = {
  position: "sticky",
  top: 0,
  zIndex: 1000,
  color: "white",
  display: "flex",
  alignItems: "center",
};

export const Header: FC<{ style?: CSSProperties }> = ({
  style = undefined,
}) => {
  style = { ...defaultStyle, ...style };
  return (
    <Layout.Header style={style} className="site-header">
      <Row style={{ width: "100%" }}>
        <Col
          className="brand-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Link to="/" className="site-logo">
            <MainLogo />
          </Link>
          <Link to="/" className="site-name">
            NBA Engine
            <br />
            Configuration Tool
          </Link>
        </Col>
        <Col
          style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
        >
          {/* <Space>
            <UserAvatar user={user} />
            <Button type="link" style={{ fontWeight: "bold", color: "white" }}>
              <LogoutOutlined style={{ fontSize: 20 }} /> Log Out
            </Button>
          </Space> */}
        </Col>
      </Row>
    </Layout.Header>
  );
};
