import { Config } from "types/config";

export const mockConfigs: Config[] = [
  {
    nbaConfigId: "134",
    activeFlag: false,
    name: "Enrollment Season",
    desc: "Enrollment Season",
    updateTS:
      'a"Thu Jul 15 2021 14:06:28 GMT-0400 (Eastern Daylight Time)"a',
    contactRule: {
      includeFlag: true,
      uiExpStructure: JSON.parse(
        `{"id":"g-0.28898917646677136","rules":[{"id":"r-0.4999586346185927","field":"personFacts['isNewMember']","value":"true","operator":"=="},{"id":"r-0.9038707068830045","field":"personFacts['effectiveTMS']","value":"2021-07-15","operator":">="},{"id":"g-0.02216002719826715","rules":[{"id":"r-0.2109255279186959","field":"personFacts['countYTDClaims']","value":"6","operator":">"},{"id":"r-0.8074744356543135","field":"personFacts['countSavedArticles']","value":"12","operator":"=="}],"combinator":"or","not":false}],"combinator":"and","not":false}`
      ),
      uiExp:
        "isNewMember == TRUE and isNewPlanYear == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
      exp: "",
    },
    actions: [
      {
        id: "1002",
        name: "Plan Details",
        desc: "Plan Details",
        nbaActionId: "1002",
        nbaChannelId: "b123",
        actionChannelValue: 2,
        actionChannelWeight: 0,
        actionChannelRelVar: "continuousrelevancyvar",
        actionChannelIntelType: "model",
        actionChannelModelVar: "categoricalmodel",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          uiExpStructure: JSON.parse(
            `{"id":"g-0.28898917646677136","rules":[{"id":"r-0.4999586346185927","field":"personFacts['isNewMember']","value":"true","operator":"=="},{"id":"r-0.9038707068830045","field":"personFacts['effectiveTMS']","value":"2021-07-15","operator":">="},{"id":"g-0.02216002719826715","rules":[{"id":"r-0.2109255279186959","field":"personFacts['countYTDClaims']","value":"6","operator":">"},{"id":"r-0.8074744356543135","field":"personFacts['countSavedArticles']","value":"12","operator":"=="}],"combinator":"or","not":false}],"combinator":"and","not":false}`
          ),
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "b123",
        actionChannelValue: 4,
        actionChannelWeight: 20,
        actionChannelRelVar: "extrarelvar",
        actionChannelIntelType: "non model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and isNewPlanYear == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          uiExpStructure: JSON.parse(
            `{"id":"g-0.28898917646677136","rules":[{"id":"r-0.4999586346185927","field":"personFacts['isNewMember']","value":"true","operator":"=="},{"id":"r-0.9038707068830045","field":"personFacts['effectiveTMS']","value":"2021-07-15","operator":">="},{"id":"g-0.02216002719826715","rules":[{"id":"r-0.2109255279186959","field":"personFacts['countYTDClaims']","value":"6","operator":">"},{"id":"r-0.8074744356543135","field":"personFacts['countSavedArticles']","value":"12","operator":"=="}],"combinator":"or","not":false}],"combinator":"and","not":false}`
          ),
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "c123",
        actionChannelValue: 2.5,
        actionChannelWeight: 30,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "binarymodel",
        contactRule: {
          includeFlag: false,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE or countDaysCvrgEffTMS < 90",
          uiExpStructure: JSON.parse(
            `{"id":"g-0.28898917646677136","rules":[{"id":"r-0.4999586346185927","field":"personFacts['isNewMember']","value":"false","operator":"=="},{"id":"r-0.9038707068830045","field":"personFacts['effectiveTMS']","value":"2021-08-01","operator":">="},{"id":"g-0.02216002719826715","rules":[{"id":"r-0.2109255279186959","field":"personFacts['countYTDClaims']","value":"6","operator":">"},{"id":"r-0.8074744356543135","field":"personFacts['countSavedArticles']","value":"12","operator":"<"}],"combinator":"and","not":false}],"combinator":"and","not":false}`
          ),
          exp: "",
        },
        nbaChannelName: "contactpage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
    ],
  },
  {
    nbaConfigId: "552",
    activeFlag: false,
    name: "California Only",
    desc: "California Only",
    updateTS:
      'ISODate("Thu Jul 15 2021 14:06:28 GMT-0400 (Eastern Daylight Time)")',
    contactRule: {
      includeFlag: false,
      uiExp:
        "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE or countDaysCvrgEffTMS < 90",
      exp: "",
    },
    actions: [],
  },
  {
    nbaConfigId: "36578",
    activeFlag: false,
    name: "Seniors",
    desc: "Seniors",
    updateTS:
      'ISODate("Thu Jul 15 2021 14:06:28 GMT-0400 (Eastern Daylight Time)")',
    contactRule: {
      includeFlag: true,
      uiExp:
        "isNewPlanYear == FALSE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE",
      exp: "",
    },
    actions: [
      {
        id: "1005",
        nbaActionId: "1005",
        nbaChannelId: "b123",
        actionChannelValue: 4,
        actionChannelWeight: 10,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 6,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
        desc: "My Health Dashboard",
        name: "My Health Dashboard",
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "b123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: false,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE or countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "a123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-sydney",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
    ],
  },
  {
    nbaConfigId: "43",
    activeFlag: true,
    name: "General",
    desc: "General",
    updateTS:
      'ISODate("Thu Jul 15 2021 14:06:28 GMT-0400 (Eastern Daylight Time)")',
    contactRule: {
      includeFlag: false,
      uiExp: "isNewMember == TRUE",
      exp: "",
    },
    actions: [
      {
        id: "1005",
        nbaActionId: "1005",
        nbaChannelId: "a123",
        actionChannelValue: 10,
        actionChannelWeight: 20,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-sydney",
        nbaChannelCost: 12,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
        desc: "My Health Dashboard",
        name: "My Health Dashboard",
      },
      {
        id: "1005",
        nbaActionId: "1005",
        nbaChannelId: "b123",
        actionChannelValue: 4,
        actionChannelWeight: 10,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 6,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
        desc: "My Health Dashboard",
        name: "My Health Dashboard",
      },
      {
        id: "1002",
        name: "Plan Details",
        desc: "Plan Details",
        nbaActionId: "1002",
        nbaChannelId: "b123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: false,
          uiExp: "isNewMember == TRUE",
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "b123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewPlanYear == FALSE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE",
          exp: "",
        },
        nbaChannelName: "homepage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "a123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewMember == TRUE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE and countDaysCvrgEffTMS < 90",
          exp: "",
        },
        nbaChannelName: "homepage-sydney",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
      {
        id: "1004",
        name: "Kodiak",
        desc: "Kodiak",
        nbaActionId: "1004",
        nbaChannelId: "c123",
        actionChannelValue: 3,
        actionChannelWeight: 0,
        actionChannelRelVar: "relevancyscore",
        actionChannelIntelType: "model",
        actionChannelModelVar: "likelihoodscore",
        contactRule: {
          includeFlag: true,
          uiExp:
            "isNewPlanYear == FALSE and hasViewedIDCard == FALSE and hasDownloadedIDCard == TRUE",
          exp: "",
        },
        nbaChannelName: "contactpage-web",
        nbaChannelCost: 2,
        nbaChannelDigital: true,
        nbaChannelOutbound: true,
      },
    ],
  },
];
