import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Config, Action, ConfigActionChannel } from "types/config";
import { FC, useEffect } from "react";
import {
  actionsActions,
  actionsSelectors,
  useDispatch,
  useSelector,
} from "store";

type ConfigSummaryAction = Action & {
  actionChannels: ConfigActionChannel[];
};

const configColumns: ColumnsType<Config> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    key: "active",
    title: "Active",
    render: (value, config) => (config.activeFlag ? "Yes" : "No"),
    width: 87,
  },
  {
    key: "contactRule",
    title: "Contact Rule",
    render: (value, { contactRule }) => contactRule.exp || contactRule.uiExp,
  },
  {
    key: "lastSaved",
    title: "Last Saved",
    ellipsis: true,
    width: 180,
    render: (value, config) => {
      // TODO: Process date fields in API so we don't have to parse them with split
      if (config.updateTS) {
        const date = new Date(config.updateTS.split('"')[1]);
        return date.toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        });
      }
    },
  },
];

const summaryActionColumns: ColumnsType<ConfigSummaryAction> = [
  {
    key: "id",
    title: "ID",
    align: "center",
    dataIndex: "id",
    width: 50,
  },
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
];

const summaryActionChannelColumns: ColumnsType<ConfigActionChannel> = [
  {
    key: "channel",
    title: "Channel",
    dataIndex: "nbaChannelName",
    render: (v) => <Typography.Text strong>{v}</Typography.Text>,
  },
  {
    key: "value",
    title: "Value",
    dataIndex: "actionChannelValue",
    render: (v: ConfigActionChannel["actionChannelValue"]) =>
      `$${v.toFixed(2)}`,
    width: 120,
  },
  {
    key: "weight",
    title: "Weight",
    dataIndex: "actionChannelWeight",
    render: (v: ConfigActionChannel["actionChannelWeight"]) => `${v}%`,
    width: 120,
  },
  {
    key: "relevance",
    title: "Relevance",
    dataIndex: "actionChannelRelVar",
    width: 200,
  },
  {
    key: "intel",
    title: "Intel Type",
    dataIndex: "actionChannelIntelType",
    width: 150,
  },
  {
    key: "model",
    title: "Model Likelihood",
    dataIndex: "actionChannelModelVar",
    width: 200,
  },
];

const expandedRowRender = (action: ConfigSummaryAction) => (
  <Table<ConfigActionChannel>
    rowKey={(ac) => ac.nbaChannelId}
    columns={summaryActionChannelColumns}
    dataSource={action.actionChannels}
    pagination={false}
    expandable={{
      expandedRowRender: (actionChannel) => (
        <div>
          Contact Rule:{" "}
          {actionChannel.contactRule.exp === ""
            ? actionChannel.contactRule.uiExp
            : actionChannel.contactRule.exp}
        </div>
      ),
      defaultExpandAllRows: true,
      expandIcon: () => "",
    }}
  />
);

export const ConfigSummaryTable: FC<{ config: Config }> = ({ config }) => {
  // Fetch actions
  const dispatch = useDispatch();
  const actions = useSelector(actionsSelectors.actionsList);
  const actionsStatus = useSelector((s) => s.actions.status);
  useEffect(() => {
    if (actionsStatus === "idle") {
      dispatch(actionsActions.fetchActions());
    }
  }, [actionsStatus, dispatch]);

  const configSummaryActions: ConfigSummaryAction[] = actions
    .filter((a) => config.actions.some((ac) => ac.id === a.id))
    .map((a) => ({
      ...a,
      actionChannels: config.actions.filter((ac) => ac.id === a.id),
    }));

  return (
    <Space direction="vertical" size="middle">
      <div>
        <Typography.Text strong>Global Parameters</Typography.Text>
        <Table<Config>
          dataSource={[config]}
          columns={configColumns}
          pagination={false}
          size="small"
        />
      </div>
      <div>
        <Typography.Text strong>Action/Channel Parameters</Typography.Text>
        <Table<ConfigSummaryAction>
          rowKey={(csa) => csa.id}
          columns={summaryActionColumns}
          dataSource={configSummaryActions}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: true,
            expandIcon: () => "",
          }}
          pagination={false}
          size="small"
        />
      </div>
    </Space>
  );
};
