import { Space, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { Config, Action } from "types/config";

export const buildActionColumns = (
  config: Config | undefined
): ColumnsType<Action> => {
  return [
    {
      key: "commands",
      title: "",
      align: "left",
      render: (value, action) => (
        <Space size="small">
          <Link to={`/configs/${config?.nbaConfigId ?? 0}/${action.id}`}>
            <EditOutlined />
          </Link>
        </Space>
      ),
      width: 54,
    },
    {
      key: "description",
      title: "Name",
      render: (value, action) => (
        <Link to={`/configs/${config?.nbaConfigId ?? 0}/${action.id}`}>
          {action.desc}
        </Link>
      ),
      ellipsis: true,
    },
    {
      key: "id",
      title: "ID",
      width: 110,
      render: (value, action) => action.id,
    },
    {
      title: "Configured Channels",
      key: "configuredchannels",
      render: (value, action) => (
        <>
          {config?.actions
            .filter((ac) => ac.id === action.id)
            .map((ac) => {
              return <Tag key={ac.nbaChannelId}>{ac.nbaChannelName}</Tag>;
            })}
        </>
      ),
      width: 400,
    },
  ];
};
