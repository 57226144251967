import { Channel } from "types/channel";

export const mockChannels: Channel[] = [
  {
    nbaChannelName: "homepage-sydney",
    nbaChannelId: "a123",
    channelId: "a123",
    nbaChannelCost: 0.1,
    nbaChannelDigital: true,
    nbaChannelOutbound: false,
  },
  {
    nbaChannelName: "homepage-web",
    nbaChannelId: "b123",
    channelId: "b123",
    nbaChannelCost: 0.13,
    nbaChannelDigital: true,
    nbaChannelOutbound: false,
  },
  {
    nbaChannelName: "contactpage-web",
    nbaChannelId: "c123",
    channelId: "c123",
    nbaChannelCost: 0.07,
    nbaChannelDigital: true,
    nbaChannelOutbound: true,
  },
  {
    nbaChannelName: "email",
    nbaChannelId: "d123",
    channelId: "d123",
    nbaChannelCost: 0.07,
    nbaChannelDigital: true,
    nbaChannelOutbound: false,
  },
];
