import {
  Config,
  Action,
  ConfigContactRule,
  ConfigListItem,
} from "types/config";
import {
  ApiError,
  ChannelResponse,
  ConfigApi,
  ConfigResponse,
} from "types/services";
import { FactsVar, Intel, ModelVar, RelevancyVar } from "types/support";
import { Channel } from "types/channel";
import axiosModule from "axios";
import { v4 as uuid } from "uuid";

import { mockConfigList } from "config/data/mockConfigList";
import { mockConfigs } from "config/data/mockConfigs";
import { mockChannels } from "config/data/mockChannels";
import { mockConfigActions } from "config/data/mockConfigActions";
import { mockFactsVariables } from "config/data/mockFactsVariables";
import { mockRelevancyVars } from "config/data/mockRelevancyVariables";
import { mockIntelTypes } from "config/data/mockIntelTypes";
import { mockModelVars } from "config/data/mockModelVariables";

type ApiChannelList = {
  nbaChannelList: Channel[];
};

type ApiConfigList = {
  nbaConfigList: ConfigListItem[];
};

type ApiActionList = {
  supportActionList: Action[];
};

type ApiFactsVarList = {
  supportFactsVars: FactsVar[];
};

type ApiRelevancyVarList = {
  supportRelevancyVars: RelevancyVar[];
};
type ApiIntelList = {
  supportIntelTypes: Intel[];
};
type ApiModelVarList = {
  supportModelVars: ModelVar[];
};

// TODO: Improve the way transforms from/to DTO are handled. Look into Axios interceptors.
type ConfigDto = Omit<Config, "contactRule"> & {
  contactRule: ConfigContactRule | {};
  includeRules: true;
};

const axios = axiosModule.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { TempToken: "##2021testTOKENforITXui2021##" },
});

const errorRate = 0.0;
const enableApi = process.env.REACT_APP_API_ENABLE === "true";
const enableWrites = process.env.REACT_APP_API_ENABLE_WRITES === "true";

// TODO: This list of functions is super-repetitive. Could refactor, maybe using generics to
// consolidate most of these.

const fetchConfigList = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiConfigList>("nbaconfig")).data.nbaConfigList;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<ConfigListItem[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockConfigList);
        } else {
          rej(Error("error fetching configs"));
        }
      }, 750);
    });
  }
};

const fetchConfigById = async (id: Config["nbaConfigId"]) => {
  if (enableApi) {
    try {
      const configDto = (await axios.get<ConfigDto>(`nbaconfig/${id}`)).data;
      const config: Config = {
        ...configDto,
        contactRule: {
          includeFlag: true,
          uiExp: "",
          exp: "",
          ...configDto.contactRule,
        },
      };

      return config;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<Config | undefined>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockConfigs.find((c) => c.nbaConfigId === id));
        } else {
          rej(Error(`error fetching config ${id}`));
        }
      }, 750);
    });
  }
};

const updateConfig = async (config: Config) => {
  if (enableApi && enableWrites) {
    const configDto: ConfigDto = { ...config, includeRules: true };
    if (config.contactRule.uiExp === "") {
      configDto.contactRule = {};
    }

    try {
      if (config.nbaConfigId === "0") {
        return (await axios.post<ConfigResponse>("nbaconfig", configDto)).data;
      } else {
        return (await axios.put<ConfigResponse>("nbaconfig", configDto)).data;
      }
    } catch (e) {
      throw new ApiError("ConfigUpdateError", e, "config update error");
    }
  } else {
    return new Promise<ConfigResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res({ nbaConfigId: uuid() });
        } else {
          rej(
            new ApiError("ConfigUpdateError", undefined, "config update error")
          );
        }
      }, 1250);
    });
  }
};

const activateConfig = async (id: Config["nbaConfigId"]) => {
  if (enableApi && enableWrites) {
    try {
      return (await axios.post<void>(`activate/${id}`)).data;
    } catch (e) {
      throw new ApiError("ConfigActivateError", e, "config activate error");
    }
  } else {
    return new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res();
        } else {
          rej(
            new ApiError(
              "ConfigActivateError",
              undefined,
              "config activate error"
            )
          );
        }
      }, 1250);
    });
  }
};

const deleteConfig = async (id: Config["nbaConfigId"]) => {
  if (enableApi && enableWrites) {
    try {
      return (await axios.delete<void>(`nbaconfig/${id}`)).data;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res();
        } else {
          rej(Error("error deleting config"));
        }
      }, 1250);
    });
  }
};

const fetchChannelList = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiChannelList>("nbachannel")).data
        .nbaChannelList;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<Channel[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockChannels);
        } else {
          rej(Error("error fetching channels"));
        }
      }, 750);
    });
  }
};

const fetchChannelById = async (id: Channel["nbaChannelId"]) => {
  if (enableApi) {
    return (await axios.get<Channel>(`nbachannel/${id}`)).data;
  } else {
    return new Promise<Channel | undefined>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockChannels.find((c) => c.nbaChannelId === id));
        } else {
          rej(Error(`error fetching config ${id}`));
        }
      }, 750);
    });
  }
};

const updateChannel = async (channel: Channel) => {
  if (enableApi && enableWrites) {
    try {
      return (await axios.put<ChannelResponse>("nbachannel", channel)).data;
    } catch (e) {
      throw new ApiError("ChannelUpdateError", e, "channel update error");
    }
  } else {
    return new Promise<ChannelResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res({ nbaChannelId: uuid() });
        } else {
          rej(
            new ApiError(
              "ChannelUpdateError",
              undefined,
              "channel update error"
            )
          );
        }
      }, 1250);
    });
  }
};

const fetchActionList = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiActionList>("action")).data.supportActionList;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<Action[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockConfigActions);
        } else {
          rej(Error("error fetching actions"));
        }
      }, 750);
    });
  }
};

const fetchFactsVariables = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiFactsVarList>("variables")).data
        .supportFactsVars;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<FactsVar[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockFactsVariables);
        } else {
          rej(Error("error fetching facts variables"));
        }
      }, 750);
    });
  }
};

const fetchRelevancyVariables = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiRelevancyVarList>("relevancy")).data
        .supportRelevancyVars;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<RelevancyVar[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockRelevancyVars);
        } else {
          rej(Error("error fetching facts variables"));
        }
      }, 750);
    });
  }
};

const fetchIntelTypes = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiIntelList>("intel")).data.supportIntelTypes;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<Intel[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockIntelTypes);
        } else {
          rej(Error("error fetching facts variables"));
        }
      }, 750);
    });
  }
};

const fetchModelVariables = async () => {
  if (enableApi) {
    try {
      return (await axios.get<ApiModelVarList>("model")).data.supportModelVars;
    } catch (e) {
      throw e;
    }
  } else {
    return new Promise<ModelVar[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() > errorRate) {
          res(mockModelVars);
        } else {
          rej(Error("error fetching facts variables"));
        }
      }, 750);
    });
  }
};

export const configApi: ConfigApi = {
  fetchConfigList,
  fetchConfigById,
  updateConfig,
  activateConfig,
  deleteConfig,
  fetchChannelList,
  fetchChannelById,
  fetchActionList,
  updateChannel,
  fetchFactsVariables,
  fetchRelevancyVariables,
  fetchIntelTypes,
  fetchModelVariables,
};
