import { FC, useEffect } from "react";
import { PageBase } from "components";
import { Link } from "react-router-dom";
import { Table, Space, PageHeader, Spin, Alert } from "antd";
import { EditOutlined, CheckCircleFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Channel } from "types/channel";
import {
  channelsActions,
  channelsSelectors,
  useDispatch,
  useSelector,
} from "store";

const channelColumns: ColumnsType<Channel> = [
  {
    key: "commands",
    title: "",
    align: "center",
    render: (value, channel) => (
      <Space size="small">
        <Link to={`/channels/${channel.nbaChannelId}`}>
          <EditOutlined />
        </Link>
      </Space>
    ),
    width: 54,
  },
  {
    key: "name",
    title: "Name",
    render: (value, channel) => (
      <Link to={`/channels/${channel.nbaChannelId}`}>
        {channel.nbaChannelName}
      </Link>
    ),
    ellipsis: true,
  },
  {
    key: "id",
    title: "ID",
    width: 180,
    render: (value, channel) => channel.channelId,
  },
  {
    key: "cost",
    title: "Cost",
    render: (value, channel) => <span>${channel.nbaChannelCost}</span>,
    width: 120,
  },
  {
    key: "digital",
    title: "Digital",
    align: "center",
    render: (value, channel) =>
      channel.nbaChannelDigital ? (
        <CheckCircleFilled style={{ color: "#177ddc" }} />
      ) : (
        ""
      ),
    width: 80,
  },
  {
    key: "outbound",
    title: "Outbound",
    align: "center",
    render: (value, channel) =>
      channel.nbaChannelOutbound ? (
        <CheckCircleFilled style={{ color: "#177ddc" }} />
      ) : (
        ""
      ),
    width: 110,
  },
];

export const Channels: FC = () => {
  const channels = useSelector(channelsSelectors.channelsList);
  const channelsStatus = useSelector((s) => s.channels.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (channelsStatus === "idle") {
      dispatch(channelsActions.fetchChannels());
    }
  }, [channelsStatus, dispatch]);

  // Table setup
  const emptyText = () => {
    switch (channelsStatus) {
      case "idle":
      case "pending":
        return <Spin />;
      case "complete":
        return "No NBA engine channels yet. Contact your administrator to request a channel.";
      case "error":
      default:
        return (
          <Alert
            type="error"
            message="An error occurred while loading channels."
            showIcon
          />
        );
    }
  };

  return (
    <PageBase>
      <PageHeader title="Available Channels" />
      <Table<Channel>
        pagination={false}
        columns={channelColumns}
        dataSource={channels}
        locale={{ emptyText }}
        rowKey={(channel) => channel.channelId}
      />
    </PageBase>
  );
};
