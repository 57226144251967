import { FC, useEffect, useState } from "react";
import { ExpressionBuilder, PageBase } from "components";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  PageHeader,
  Checkbox,
  Alert,
  Spin,
  message,
  FormInstance,
} from "antd";
import {
  actionsActions,
  actionsSelectors,
  channelsActions,
  channelsSelectors,
  configsActions,
  configsSelectors,
  supportActions,
  supportSelectors,
  useDispatch,
  useSelector,
} from "store";
import { ConfigActionChannel, ConfigContactRule } from "types/config";
import { ActionChannel, ActionChannelFormData } from "./ActionChannel";
import { getLeastCompleteStatus } from "utils";
import { Channel } from "types/channel";
import { unwrapResult } from "@reduxjs/toolkit";
import { RequestStatus } from "types/services";

// TODO: This file is too long and has too much repetitive code, needs to be refactored
// In particular, it might be good to rework the form handling; turns out Ant forms would work
// much better as a single form with repeating elements instead of multiple forms per page

type ActionChannelData = Pick<
  ConfigActionChannel,
  | "nbaChannelId"
  | "contactRule"
  | "actionChannelValue"
  | "actionChannelWeight"
  | "actionChannelRelVar"
  | "actionChannelIntelType"
  | "actionChannelModelVar"
>;

type ActionChannelStateItem = {
  form?: FormInstance<ActionChannelFormData>;
} & Pick<ConfigActionChannel, "nbaChannelId" | "contactRule">;

const defaultContactRule = { uiExp: "", exp: "", includeFlag: true };

export const ConfigAction: FC = () => {
  const { id, actionId } = useParams<{ id: string; actionId: string }>();
  const dispatch = useDispatch();
  const [
    actionChannelsUpdateRequestStatus,
    setActionChannelsUpdateRequestStatus,
  ] = useState<RequestStatus>("idle");
  const history = useHistory();

  // Fetch config
  const selectedConfig = useSelector(configsSelectors.selectedConfig);
  useEffect(() => {
    if (selectedConfig.status === "idle" || selectedConfig.id !== id) {
      dispatch(configsActions.fetchConfigById(id));
    }
  }, [dispatch, id, selectedConfig]);

  // Fetch action
  const action = useSelector((s) => actionsSelectors.actionById(s, actionId));
  const actionsStatus = useSelector((s) => s.actions.status);
  useEffect(() => {
    if (actionsStatus === "idle") {
      dispatch(actionsActions.fetchActions());
    }
  }, [actionsStatus, dispatch, actionId]);

  // Fetch channels
  const channels = useSelector(channelsSelectors.channelsList);
  const channelsStatus = useSelector((s) => s.channels.status);
  useEffect(() => {
    if (channelsStatus === "idle") {
      dispatch(channelsActions.fetchChannels());
    }
  }, [channelsStatus, dispatch]);

  // Fetch relevancy vars
  const relevancyVars = useSelector(supportSelectors.relevancyList);
  const relevancyStatus = useSelector(supportSelectors.relevancyStatus);
  useEffect(() => {
    if (relevancyStatus === "idle") {
      dispatch(supportActions.fetchRelevancyVariables());
    }
  }, [relevancyStatus, dispatch]);

  // Fetch intel types
  const intelTypes = useSelector(supportSelectors.intelList);
  const intelStatus = useSelector(supportSelectors.intelStatus);
  useEffect(() => {
    if (intelStatus === "idle") {
      dispatch(supportActions.fetchIntelTypes());
    }
  }, [intelStatus, dispatch]);

  // Fetch model vars
  const modelVars = useSelector(supportSelectors.modelList);
  const modelStatus = useSelector(supportSelectors.modelStatus);
  useEffect(() => {
    if (modelStatus === "idle") {
      dispatch(supportActions.fetchModelVariables());
    }
  }, [modelStatus, dispatch]);

  // Set up action/channel state once config loads
  const [actionChannelsActive, setActionChannelsActive] = useState<
    Channel["nbaChannelId"][]
  >([]);
  useEffect(() => {
    if (selectedConfig.status === "complete") {
      const activeActionChannels = selectedConfig.config
        ? selectedConfig.config.actions.filter(
            (ac) => ac.nbaActionId === actionId
          )
        : [];

      setActionChannelsActive(
        activeActionChannels.map((ac) => ac.nbaChannelId)
      );
    }
  }, [selectedConfig, actionId]);

  const [actionChannelState, setActionChannelState] = useState<
    ActionChannelStateItem[]
  >([]);
  useEffect(() => {
    if (selectedConfig.status === "complete" && channelsStatus === "complete") {
      const actionChannelState: ActionChannelStateItem[] = channels.map((c) => {
        const actionChannel = selectedConfig.config?.actions.find(
          (ac) =>
            ac.nbaActionId === actionId && ac.nbaChannelId === c.nbaChannelId
        );

        return {
          nbaChannelId: c.nbaChannelId,
          contactRule: actionChannel?.contactRule ?? defaultContactRule,
        };
      });

      setActionChannelState(actionChannelState);
    }
  }, [channels, channelsStatus, selectedConfig, actionId]);

  // useEffect(() => {
  //   console.log(actionChannelsUpdateRequestStatus);
  // }, [actionChannelsUpdateRequestStatus]);

  const toggleActive = (nbaChannelId: ConfigActionChannel["nbaChannelId"]) => {
    setActionChannelsActive((state) => {
      return state.includes(nbaChannelId)
        ? state.filter((s) => s !== nbaChannelId)
        : [...state, nbaChannelId];
    });
  };

  const setContactRule = (
    nbaChannelId: Channel["nbaChannelId"],
    contactRule: ConfigContactRule
  ) => {
    setActionChannelState((s) =>
      s.map((ac) =>
        ac.nbaChannelId === nbaChannelId ? { ...ac, contactRule } : ac
      )
    );
  };

  const onFormReady = (
    nbaChannelId: Channel["nbaChannelId"],
    form: FormInstance<ActionChannelFormData>
  ) => {
    setActionChannelState((s) =>
      s.map((ac) => (ac.nbaChannelId === nbaChannelId ? { ...ac, form } : ac))
    );
  };

  const validateActionChannelForms = async () => {
    // TODO: Might be able to simplify things by taking form data from the return on validateFields,
    // could allow skipping much of the prepareActionChannelData step
    return Promise.all(
      actionChannelState.map(async (ac) => await ac.form?.validateFields())
    );
  };

  const prepareActionChannelData = () => {
    const actionChannelDataSet: ActionChannelData[] = actionChannelState
      .filter((ac) => actionChannelsActive.includes(ac.nbaChannelId))
      .map((ac) => {
        if (!ac.form) {
          throw Error(
            `Form for channel ${ac.nbaChannelId} not found during action/channel update.`
          );
        }
        // ac.form.submit();
        const actionChannelFormData = ac.form.getFieldsValue();
        return {
          nbaChannelId: ac.nbaChannelId,
          contactRule: ac.contactRule,
          ...actionChannelFormData,
          // Ant's number-typed inputs (InputNumber) are lacking in functionality so we use
          // regular inputs in the form, and hence have to convert types here
          actionChannelValue: Number(actionChannelFormData.actionChannelValue),
          actionChannelWeight: Number(
            actionChannelFormData.actionChannelWeight ?? 0
          ),
        };
      });

    return actionChannelDataSet;
  };

  const updateActionChannels = async (
    actionChannelDataSet: ActionChannelData[]
  ) => {
    const actionChannels: ConfigActionChannel[] = actionChannelDataSet.map(
      (ac) => {
        const channel = channels.find(
          (c) => c.nbaChannelId === ac.nbaChannelId
        );

        if (!channel || !action) {
          throw Error(
            `Action/channel info for channel with id ${ac.nbaChannelId} not found during action/channel update.`
          );
        }

        return {
          ...action,
          nbaActionId: action?.id,
          ...channel,
          ...ac,
        };
      }
    );

    unwrapResult(
      await dispatch(
        configsActions.updateActionChannelsByActionId({
          nbaActionId: actionId,
          actionChannels,
        })
      )
    );
  };

  const onActionChannelFormSubmit = async () => {
    setActionChannelsUpdateRequestStatus("pending");
    try {
      await validateActionChannelForms();
    } catch {
      setActionChannelsUpdateRequestStatus("error");
      message.error(
        "Not all selected action/channel settings are valid. Please review your entries and try again."
      );
      return;
    }

    try {
      const actionChannelDataSet = prepareActionChannelData();
      await updateActionChannels(actionChannelDataSet);
      setActionChannelsUpdateRequestStatus("complete");

      message.success("Action/channel settings successfully updated.");
      history.push(`/configs/${id}`);
    } catch {
      setActionChannelsUpdateRequestStatus("error");
      message.error("Action/channel update failed. Please try again.");
    }
  };

  // TODO: Status handling is a candidate for refactor - extract to a function, create a reusable component, etc.
  switch (
    getLeastCompleteStatus([
      actionsStatus,
      selectedConfig.status,
      channelsStatus,
      relevancyStatus,
      intelStatus,
      modelStatus,
    ])
  ) {
    case "idle":
    case "pending":
      return (
        <PageBase>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Spin size="large" />
          </div>
        </PageBase>
      );
    case "error":
      return (
        <PageBase>
          <PageHeader title="Edit Action" />
          <Alert
            type="error"
            message="An error occurred while loading this action."
            showIcon
          />
        </PageBase>
      );
    case "complete":
    default:
      if (!action) {
        return (
          <PageBase>
            <PageHeader title="Edit Action" />
            <Alert
              type="error"
              message="The specified action does not exist."
              showIcon
            />
          </PageBase>
        );
      }

      if (!selectedConfig.config) {
        return (
          <PageBase>
            <PageHeader title="Edit Action" />
            <Alert
              type="error"
              message="An error occurred while loading this action."
              showIcon
            />
          </PageBase>
        );
      }
      break;
  }

  return (
    <PageBase>
      <PageHeader
        title="Edit Action"
        extra={
          <Button
            type="primary"
            onClick={onActionChannelFormSubmit}
            loading={actionChannelsUpdateRequestStatus === "pending"}
          >
            Save
          </Button>
        }
      />

      <Form layout="vertical">
        <Row gutter={16}>
          <Col className="gutter-row">
            <Form.Item initialValue={action.id} name={"id"} label="ID">
              <Input disabled style={{ width: "90px" }} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={"auto"}>
            <Form.Item initialValue={action.name} name={"name"} label="Name">
              <Input disabled style={{ width: "100%", maxWidth: "520px" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div>
        Action/Channels
        {channels.map((channel, index) => {
          const actionChannel = selectedConfig.config?.actions.find(
            (ac) =>
              ac.nbaChannelId === channel.nbaChannelId &&
              ac.nbaActionId === actionId
          );
          const actionChannelStateItem = actionChannelState.find(
            (ac) => ac.nbaChannelId === channel.nbaChannelId
          );

          return (
            <div key={index}>
              <Checkbox
                data-id={index}
                onClick={() => {
                  toggleActive(channel.nbaChannelId);
                }}
                checked={actionChannelsActive.includes(channel.nbaChannelId)}
              >
                {channel.nbaChannelName}
              </Checkbox>

              {actionChannelsActive.includes(channel.nbaChannelId) && (
                <div className="action-channel">
                  <ActionChannel
                    channel={channel}
                    supportVars={{ relevancyVars, intelTypes, modelVars }}
                    actionChannel={actionChannel}
                    form={actionChannelStateItem?.form}
                    onFormReady={onFormReady}
                  />
                  Contact Rules (Optional)
                  <ExpressionBuilder
                    contactRule={actionChannelStateItem?.contactRule}
                    onExpressionChange={(cr) =>
                      setContactRule(channel.nbaChannelId, cr)
                    }
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </PageBase>
  );
};
