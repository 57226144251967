import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { FactsVar, Intel, ModelVar, RelevancyVar } from "types/support";
import { RequestStatus } from "types/services";
import { RootState } from "store";
import { configApi } from "services";

// TODO: This file has a ton of repetitive code, could refactor. Generics might help.
// Other slice files could use help too but this might be the worst offender.

type SupportState = {
  facts: {
    list: FactsVar[];
    status: RequestStatus;
    error: SerializedError["message"];
  };
  relevancy: {
    list: RelevancyVar[];
    status: RequestStatus;
    error: SerializedError["message"];
  };
  intel: {
    list: Intel[];
    status: RequestStatus;
    error: SerializedError["message"];
  };
  model: {
    list: ModelVar[];
    status: RequestStatus;
    error: SerializedError["message"];
  };
};

const status: RequestStatus = "idle";
const initialSubState = {
  list: [],
  status,
  error: undefined,
};
const initialState: SupportState = {
  facts: initialSubState,
  relevancy: initialSubState,
  intel: initialSubState,
  model: initialSubState,
};

const fetchFactsVariables = createAsyncThunk(
  "support/fetchFactsVariables",
  async () => {
    return await configApi.fetchFactsVariables();
  }
);
const fetchRelevancyVariables = createAsyncThunk(
  "support/fetchRelevancyVariables",
  async () => {
    return await configApi.fetchRelevancyVariables();
  }
);
const fetchIntelTypes = createAsyncThunk(
  "support/fetchIntelTypes",
  async () => {
    return await configApi.fetchIntelTypes();
  }
);
const fetchModelVariables = createAsyncThunk(
  "support/fetchModelVariables",
  async () => {
    return await configApi.fetchModelVariables();
  }
);

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    // Example of a regular, non-async action - note use of PayloadAction to type payload
    // deleteConfig: (state, action: PayloadAction<number>) => {
    //   state.list = state.list.filter((i) => i.id !== action.payload);
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFactsVariables.pending, (state) => {
        state.facts.status = "pending";
      })
      .addCase(fetchFactsVariables.fulfilled, (state, action) => {
        state.facts.status = "complete";
        state.facts.list = action.payload;
      })
      .addCase(fetchFactsVariables.rejected, (state, action) => {
        state.facts.status = "error";
        state.facts.error = action.error.message;
      })
      .addCase(fetchRelevancyVariables.pending, (state) => {
        state.relevancy.status = "pending";
      })
      .addCase(fetchRelevancyVariables.fulfilled, (state, action) => {
        state.relevancy.status = "complete";
        state.relevancy.list = action.payload;
      })
      .addCase(fetchRelevancyVariables.rejected, (state, action) => {
        state.relevancy.status = "error";
        state.relevancy.error = action.error.message;
      })
      .addCase(fetchIntelTypes.pending, (state) => {
        state.intel.status = "pending";
      })
      .addCase(fetchIntelTypes.fulfilled, (state, action) => {
        state.intel.status = "complete";
        state.intel.list = action.payload;
      })
      .addCase(fetchIntelTypes.rejected, (state, action) => {
        state.intel.status = "error";
        state.intel.error = action.error.message;
      })
      .addCase(fetchModelVariables.pending, (state) => {
        state.model.status = "pending";
      })
      .addCase(fetchModelVariables.fulfilled, (state, action) => {
        state.model.status = "complete";
        state.model.list = action.payload;
      })
      .addCase(fetchModelVariables.rejected, (state, action) => {
        state.model.status = "error";
        state.model.error = action.error.message;
      });
  },
});

export const supportReducer = supportSlice.reducer;
export const supportActions = {
  ...supportSlice.actions,
  fetchFactsVariables,
  fetchRelevancyVariables,
  fetchIntelTypes,
  fetchModelVariables,
};
export const supportSelectors = {
  factsList: (state: RootState) => state.support.facts.list,
  factsStatus: (state: RootState) => state.support.facts.status,
  relevancyList: (state: RootState) => state.support.relevancy.list,
  relevancyStatus: (state: RootState) => state.support.relevancy.status,
  intelList: (state: RootState) => state.support.intel.list,
  intelStatus: (state: RootState) => state.support.intel.status,
  modelList: (state: RootState) => state.support.model.list,
  modelStatus: (state: RootState) => state.support.model.status,
};
