import { RouterConfig } from "navigation";
import { FC, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { configsActions, useDispatch, useSelector } from "store";

export const App: FC = () => {
  const configsStatus = useSelector((s) => s.configs.listStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (configsStatus === "idle") {
      dispatch(configsActions.fetchConfigs());
    }
  }, [configsStatus, dispatch]);

  return (
    <Router>
      <RouterConfig />
    </Router>
  );
};
