import { FC } from "react";
import { PageBase } from "components";

export const PageNotFound: FC = (props) => {
  return (
    <PageBase>
      <h1>Page Not Found</h1>
      <div>We couldn't find the page you're looking for.</div>
    </PageBase>
  );
};
