import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { Action } from "types/config";
import { RequestStatus } from "types/services";
import { RootState } from "store";
import { configApi } from "services";

type ActionState = {
  list: Action[];
  status: RequestStatus;
  error: SerializedError["message"];
};
const initialState: ActionState = {
  list: [],
  status: "idle",
  error: undefined,
};

const fetchActions = createAsyncThunk("configs/fetchActions", async () => {
  return await configApi.fetchActionList();
});

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    // Example of a regular, non-async action - note use of PayloadAction to type payload
    // deleteConfig: (state, action: PayloadAction<number>) => {
    //   state.list = state.list.filter((i) => i.id !== action.payload);
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchActions.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchActions.fulfilled, (state, action) => {
        state.status = "complete";
        state.list = action.payload;
      })
      .addCase(fetchActions.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const actionsReducer = actionsSlice.reducer;
export const actionsActions = {
  ...actionsSlice.actions,
  fetchActions,
};
export const actionsSelectors = {
  actionsList: (state: RootState) => state.actions.list,
  actionById: (state: RootState, id: Action["id"]) =>
    state.actions.list.find((a) => id === a.id),
};
