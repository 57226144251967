import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { Config, ConfigActionChannel, ConfigListItem } from "types/config";
import { ConfigResponse, RequestStatus } from "types/services";
import { BaseThunkApi, RootState } from "store";
import { configApi } from "services";

type ConfigState = {
  list: ConfigListItem[];
  listStatus: RequestStatus;
  listError?: SerializedError["message"];
  selected: {
    config?: Config;
    id?: Config["nbaConfigId"];
    status: RequestStatus;
    error?: SerializedError["message"];
  };
};

const initialState: ConfigState = {
  list: [],
  listStatus: "idle",
  selected: { status: "idle" },
};

const fetchConfigs = createAsyncThunk("configs/fetchConfigs", async () => {
  return await configApi.fetchConfigList();
});

const fetchConfigById = createAsyncThunk(
  "configs/fetchConfigById",
  async (id: ConfigListItem["nbaConfigId"]) => {
    return await configApi.fetchConfigById(id);
  }
);

const updateConfig = createAsyncThunk<ConfigResponse, Config, BaseThunkApi>(
  "configs/updateConfig",
  async (config, { dispatch, getState, rejectWithValue }) => {
    try {
      const updateResponse = await configApi.updateConfig(config);
      // handle config activation if needed
      try {
        if (
          config.activeFlag &&
          config.nbaConfigId !==
            configsSelectors.activeConfig(getState())?.nbaConfigId
        ) {
          if (!config.actions.length) {
            throw Error(
              "Cannot activate config with no defined action/channels"
            );
          }

          await configApi.activateConfig(updateResponse.nbaConfigId);
        }
      } finally {
        // always fetch updated config list after update, even if activate fails
        dispatch(fetchConfigs());
        // ensure that updated config is fetched next time it's viewed
        dispatch(configsActions.setSelectedConfig({ status: "idle" }));
      }

      return updateResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateActionChannelsByActionId = createAsyncThunk<
  ConfigResponse,
  {
    nbaActionId: ConfigActionChannel["nbaActionId"];
    actionChannels: ConfigActionChannel[];
  },
  BaseThunkApi
>(
  "configs/updateActionChannelsByActionId",
  async ({ nbaActionId, actionChannels }, { getState }) => {
    const config = configsSelectors.selectedConfig(getState()).config;

    if (!config) {
      throw Error("No config selected during action/channel update");
    }

    const actions = [
      ...config.actions.filter((ac) => ac.nbaActionId !== nbaActionId),
      ...actionChannels,
    ];

    return await configApi.updateConfig({ ...config, actions });
  }
);

const deleteConfig = createAsyncThunk(
  "configs/deleteConfig",
  async (id: ConfigListItem["nbaConfigId"]) => {
    return await configApi.deleteConfig(id);
  }
);

const configsSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    // Example of a regular, non-async action - note use of PayloadAction to type payload
    // deleteConfig: (state, action: PayloadAction<number>) => {
    //   state.list = state.list.filter((i) => i.id !== action.payload);
    // },
    setSelectedConfig: (
      state,
      action: PayloadAction<ConfigState["selected"]>
    ) => {
      state.selected = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigs.pending, (state) => {
        state.list = [];
        state.listStatus = "pending";
      })
      .addCase(fetchConfigs.fulfilled, (state, action) => {
        state.listStatus = "complete";
        state.list = action.payload;
      })
      .addCase(fetchConfigs.rejected, (state, action) => {
        state.listStatus = "error";
        state.listError = action.error.message;
      })
      .addCase(fetchConfigById.pending, (state, action) => {
        state.selected.status = "pending";
        state.selected.id = action.meta.arg;
      })
      .addCase(fetchConfigById.fulfilled, (state, action) => {
        state.selected.config = action.payload;
        state.selected.status = "complete";
      })
      .addCase(fetchConfigById.rejected, (state, action) => {
        state.selected.status = "error";
        state.selected.error = action.error.message;
      })
      .addCase(updateActionChannelsByActionId.fulfilled, (state) => {
        // On successful action/channel update, we should refresh config data
        state.selected.status = "idle";
        state.listStatus = "idle";
      })
      .addCase(deleteConfig.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (i) => i.nbaConfigId !== action.meta.arg
        );
      });
  },
});

export const configsReducer = configsSlice.reducer;
export const configsActions = {
  ...configsSlice.actions,
  fetchConfigs,
  fetchConfigById,
  updateConfig,
  updateActionChannelsByActionId,
  deleteConfig,
};
export const configsSelectors = {
  configsList: (state: RootState) => state.configs.list,
  configListItemById: (state: RootState, id: ConfigListItem["nbaConfigId"]) =>
    state.configs.list.find((c) => id === c.nbaConfigId),
  selectedConfig: (state: RootState) => state.configs.selected,
  activeConfig: (state: RootState) =>
    state.configs.list.find((c) => c.activeFlag),
};
