import { FC, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { ExpressionBuilder, PageBase } from "components";
import { buildActionColumns } from "./buildActionColumns";
import { ConfigSummaryTable } from "./ConfigSummaryTable";
import { Config, Action, ConfigContactRule } from "types/config";
import { ExpressionBuilderProps } from "components/ExpressionBuilder";
import { RequestStatus } from "types/services";

export type ConfigDetailViewProps = {
  config: Config;
  actions: Action[];
  onConfigGlobalFormSubmit: (configGlobalData: ConfigGlobalData) => void;
  configUpdateRequestStatus: RequestStatus;
};

type ConfigGlobalForm = Pick<Config, "name" | "activeFlag">;
type ConfigGlobalData = ConfigGlobalForm & { contactRule: ConfigContactRule };

const { TabPane } = Tabs;

export const ConfigDetailView: FC<ConfigDetailViewProps> = ({
  config,
  actions,
  onConfigGlobalFormSubmit,
  configUpdateRequestStatus,
}) => {
  // Set up state and initial form data
  const [contactRule, setContactRule] = useState<ConfigContactRule>(
    config.contactRule
  );

  const initialValues: ConfigGlobalForm = {
    name: config.name,
    activeFlag: config.activeFlag,
  };

  // Event handlers
  const onExpressionChange: ExpressionBuilderProps["onExpressionChange"] = (
    contactRule
  ) => {
    setContactRule(contactRule);
  };

  const onFinish = (form: ConfigGlobalForm) => {
    const configGlobalData: ConfigGlobalData = {
      contactRule,
      // doing this instead of ...form to avoid possible issue w/ Ant form sending extra fields
      name: form.name,
      activeFlag: form.activeFlag,
    };
    onConfigGlobalFormSubmit(configGlobalData);
  };

  // Can't deactivate the active config directly, and can't activate a config w/ no action/channel settings
  const activateDisabled =
    config.activeFlag || !config.actions.length || config.nbaConfigId === "0";
  const activateTooltipTitle =
    config.nbaConfigId === "0"
      ? "Configuration can't be activated during creation. Save your configuration before activating."
      : config.activeFlag
      ? "Active configuration may not be deactivated directly. Set another config as active to deactivate this one."
      : "Configuration must have at least one action/channel enabled before activation.";

  return (
    <PageBase>
      <Form<ConfigGlobalForm>
        initialValues={initialValues}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 22 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <PageHeader
          title={`${config.nbaConfigId === "0" ? "Add" : "Edit"} Configuration`}
          extra={
            <Form.Item noStyle>
              <Button
                key="1"
                type="primary"
                htmlType="submit"
                loading={configUpdateRequestStatus === "pending"}
              >
                Save
              </Button>
            </Form.Item>
          }
        />

        {config.nbaConfigId === "0" && (
          <p>
            To create a new configuration, first choose a name, set global
            contact rules (optional), and save. You'll then be able to set up
            action/channels for your configuration.
          </p>
        )}

        <Row align="bottom">
          <Col className="gutter-row" span={8}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Configuration name is required" },
              ]}
              label="Name"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={4}>
            <Form.Item name="activeFlag" valuePropName="checked">
              <Checkbox disabled={activateDisabled}>
                {activateDisabled ? (
                  <Tooltip title={activateTooltipTitle}>Active</Tooltip>
                ) : (
                  "Active"
                )}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1">
          {config.nbaConfigId !== "0" && (
            <TabPane tab="Actions" key={1}>
              <Table<Action>
                pagination={{ position: ["bottomLeft"] }}
                columns={buildActionColumns(config)}
                dataSource={actions}
                rowKey={(a) => a.id}
                locale={{
                  emptyText:
                    "No NBA engine actions yet. Contact your administrator to request an action.",
                }}
              />
            </TabPane>
          )}

          <TabPane tab="Global Contact Rule" key={2}>
            (Global contact rule is optional.)
            <ExpressionBuilder
              contactRule={config.contactRule}
              onExpressionChange={onExpressionChange}
            />
          </TabPane>
          {config.nbaConfigId !== "0" && (
            <TabPane tab="Summary Report" key={3}>
              <ConfigSummaryTable config={config} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    </PageBase>
  );
};
