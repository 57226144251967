import { Action } from "types/config";

export const mockConfigActions: Action[] = [
  {
    id: "1001",
    name: "IDCard",
    desc: "IDCard",
  },
  {
    id: "1002",
    name: "Plan Details",
    desc: "Plan Details",
  },
  {
    id: "1003",
    name: "Sydney App",
    desc: "Sydney App",
  },
  {
    id: "1008",
    name: "CA Reinstate",
    desc: "CA Reinstate",
  },
  {
    id: "1009",
    name: "CA Optout",
    desc: "CA Optout",
  },
  {
    id: "1004",
    name: "Kodiak",
    desc: "Kodiak",
  },
  {
    id: "1005",
    name: "My Health Dashboard",
    desc: "My Health Dashboard",
  },
  {
    id: "1006",
    name: "Member Interests",
    desc: "Member Interests",
  },
  {
    id: "1007",
    name: "OON Claim",
    desc: "OON Claim",
  },
];
