import { FC, useState } from "react";
import { PageBase } from "components";
import {
  Table,
  PageHeader,
  Button,
  Modal,
  Typography,
  Tooltip,
  Spin,
  Alert,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { ConfigListItem } from "types/config";
import {
  useSelector,
  useDispatch,
  configsActions,
  configsSelectors,
} from "store";
import { unwrapResult } from "@reduxjs/toolkit";
import { RequestStatus } from "types/services";

export const Configs: FC = (props) => {
  // Store state/actions
  const configs = useSelector(configsSelectors.configsList);
  const configsStatus = useSelector((s) => s.configs.listStatus);
  const dispatch = useDispatch();
  const deleteConfig = (config: ConfigListItem) => {
    return dispatch(configsActions.deleteConfig(config.nbaConfigId));
  };

  // Local state, etc.
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConfigRequestStatus, setDeleteConfigRequestStatus] =
    useState<RequestStatus>("idle");
  const [configToDelete, setConfigToDelete] = useState<
    ConfigListItem | undefined
  >(undefined);
  const history = useHistory();

  // Event handlers
  const handleDeleteConfigFromList = (config: ConfigListItem) => {
    setConfigToDelete(config);
    setDeleteModalOpen(true);
  };

  const handleCancelDeleteConfig = () => {
    setConfigToDelete(undefined);
    setDeleteConfigRequestStatus("idle");
    setDeleteModalOpen(false);
  };

  const handleDeleteConfig = async () => {
    setDeleteConfigRequestStatus("pending");
    if (configToDelete) {
      try {
        unwrapResult(await deleteConfig(configToDelete));
        setDeleteModalOpen(false);
        message.success("Configuration successfully deleted.");
        setDeleteConfigRequestStatus("idle");
      } catch {
        setDeleteConfigRequestStatus("error");
      }
    }
  };

  // Table setup
  const emptyText = () => {
    switch (configsStatus) {
      case "idle":
      case "pending":
        return <Spin />;
      case "complete":
        return (
          <>
            No NBA engine configurations yet.{" "}
            <Link to="/configs/0">
              Create a new configuration to get started.
            </Link>
          </>
        );
      case "error":
      default:
        return (
          <Alert
            type="error"
            message="An error occurred while loading configurations."
            showIcon
          />
        );
    }
  };

  const configColumns: ColumnsType<ConfigListItem> = [
    {
      key: "edit",
      align: "center",
      render: (value, config) => (
        <Link to={`/configs/${config.nbaConfigId}`}>
          <EditOutlined />
        </Link>
      ),
      width: 54,
    },
    {
      key: "description",
      title: "Name",
      render: (value, config) => (
        <Link to={`/configs/${config.nbaConfigId}`}>{config.name}</Link>
      ),
      ellipsis: true,
    },
    {
      key: "active",
      title: "Active",
      align: "center",
      render: (value, config) =>
        config.activeFlag ? <CheckCircleFilled /> : "",
      width: 87,
    },
    {
      key: "configuredActions",
      title: "Configured Actions",
      render: (value, config) =>
        `${config.actionsInUse} / ${config.totalActions}`,
      width: 220,
    },
    {
      key: "lastSaved",
      title: "Last Saved",
      ellipsis: true,
      width: 180,
      // render: (value, config) => config.updateTS.split(" ")[0],
      render: (value, config) => {
        // TODO: Process date fields in API so we don't have to parse them with split
        const date = new Date(config.updateTS.split('"')[1]);
        return date.toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        });
      },
    },
    {
      key: "delete",
      align: "center",
      render: (text, record) =>
        record.activeFlag ? (
          <Tooltip
            title="Active configuration may not be deleted. Set another config as active first."
            placement="left"
          >
            <DeleteOutlined className={"disabled"} />
          </Tooltip>
        ) : (
          <DeleteOutlined
            onClick={() => {
              handleDeleteConfigFromList(record);
            }}
          />
        ),
      width: 54,
    },
  ];

  return (
    <PageBase>
      <PageHeader
        title="Available Configurations"
        extra={
          <Button type="primary" onClick={() => history.push("/configs/0")}>
            <PlusCircleFilled />
            New Configuration
          </Button>
        }
      />

      <Table<ConfigListItem>
        rowKey={(config) => config.nbaConfigId}
        pagination={{ position: ["bottomLeft"] }}
        columns={configColumns}
        dataSource={configs}
        locale={{
          emptyText,
        }}
      />

      <Modal
        title="Delete Configuration?"
        visible={deleteModalOpen}
        okText="Delete"
        okButtonProps={{ danger: true }}
        onOk={handleDeleteConfig}
        confirmLoading={deleteConfigRequestStatus === "pending"}
        onCancel={handleCancelDeleteConfig}
      >
        <p>
          Are you sure you want to delete{" "}
          {<Typography.Text strong>{configToDelete?.name}</Typography.Text>}?
        </p>
        <p>
          All data associated with this configuration will be deleted, including
          global contact rule and all action/channel settings.
        </p>
        {deleteConfigRequestStatus === "error" && (
          <Alert
            type="error"
            message="An error occurred while deleting this configuration. Please try again or contact your administrator for support."
            showIcon
          />
        )}
      </Modal>
    </PageBase>
  );
};
