import { Intel } from "types/support";

export const mockIntelTypes: Intel[] = [
  {
    name: "model",
    desc: "model",
  },
  {
    name: "non model",
    desc: "Business rule",
  },
];
