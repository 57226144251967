import { FC, useEffect, useState } from "react";
import { PageBase } from "components";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Alert, Spin, message, Modal, Typography } from "antd";
import { Config, ConfigListItem } from "types/config";
import {
  configsActions,
  configsSelectors,
  useDispatch,
  useSelector,
  actionsActions,
  actionsSelectors,
} from "store";

import { unwrapResult } from "@reduxjs/toolkit";
import { RequestStatus, ApiError } from "types/services";
import { ConfigDetailView, ConfigDetailViewProps } from "./ConfigDetailView";
import { getLeastCompleteStatus } from "utils";

const draftConfig: Config = {
  nbaConfigId: "0",
  activeFlag: false,
  name: "",
  desc: "",
  actions: [],
  contactRule: {
    includeFlag: true,
    exp: "",
    uiExp: "",
  },
};

export const ConfigDetail: FC = () => {
  /***********
   * STATE/STORE INITIALIZATION
   ***********/
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [configUpdateRequestStatus, setConfigUpdateRequestStatus] =
    useState<RequestStatus>("idle");

  const dispatch = useDispatch();

  // Fetch config data
  const configsStatus = useSelector((s) => s.configs.listStatus);
  const activeConfig = useSelector(configsSelectors.activeConfig);
  const selectedConfig = useSelector(configsSelectors.selectedConfig);
  useEffect(() => {
    if (selectedConfig.status === "idle" || selectedConfig.id !== id) {
      if (id === "0") {
        dispatch(
          configsActions.setSelectedConfig({
            id: "0",
            config: draftConfig,
            status: "complete",
          })
        );
      } else {
        dispatch(configsActions.fetchConfigById(id));
      }
    }
  }, [selectedConfig, dispatch, id]);

  // Fetch actions
  const actions = useSelector(actionsSelectors.actionsList);
  const actionsStatus = useSelector((s) => s.actions.status);
  useEffect(() => {
    if (actionsStatus === "idle") {
      dispatch(actionsActions.fetchActions());
    }
  }, [actionsStatus, dispatch]);

  /***********
   * EVENT HANDLERS
   ***********/
  // TODO: Could refactor some of this, maybe have an updateConfigGlobalData thunk that would handle
  // applying the supplied data to the full config object instead of building a config here
  const onConfigGlobalFormSubmit: ConfigDetailViewProps["onConfigGlobalFormSubmit"] =
    (configGlobalData) => {
      // 1. Ensure a defined config as base, 2. Apply changes from config detail form
      const config: Config = {
        ...(selectedConfig.config ?? draftConfig),
        ...configGlobalData,
      };

      // If user is attempting to activate when another config is active, get confirmation first
      if (
        configGlobalData.activeFlag &&
        activeConfig &&
        activeConfig.nbaConfigId !== id
      ) {
        confirmConfigActivation(() => updateConfigGlobal(config), activeConfig);
      } else {
        updateConfigGlobal(config);
      }
    };

  const confirmConfigActivation = (
    onActivateConfirm: () => Promise<void>,
    activeConfig: ConfigListItem
  ) => {
    Modal.confirm({
      content: (
        <>
          You have marked this configuration as active. The currently active
          configuration is{" "}
          <Typography.Text strong>{activeConfig.name}</Typography.Text>. Only
          one configuration can be active at a time; do you want to deactivate
          the current active configuration and make this the active one?
        </>
      ),
      okText: "Yes, save my changes",
      cancelText: "No, continue editing",
      onOk: onActivateConfirm,
    });
  };

  const updateConfigGlobal = async (config: Config) => {
    setConfigUpdateRequestStatus("pending");

    try {
      unwrapResult(await dispatch(configsActions.updateConfig(config)));

      setConfigUpdateRequestStatus("complete");
      message.success("Configuration successfully updated.");
      history.push("/configs");
    } catch (e) {
      setConfigUpdateRequestStatus("error");
      // TODO: Could move some of this into the store, e.g. by tracking update error status/type
      // there, which would remove some API implementation details from this component.
      let errMsg = "Configuration update failed. Please try again.";

      if (e instanceof ApiError && e.errType === "ConfigActivateError") {
        errMsg =
          "Configuration update succeeded, but activation failed. Please try again.";
      }

      message.error(errMsg);
    }
  };

  /***********
   * STATUS HANDLING
   ***********/
  // TODO: Status handling is a candidate for refactor - extract to a function, create a reusable component, etc.
  switch (
    getLeastCompleteStatus([
      configsStatus,
      actionsStatus,
      selectedConfig.status,
    ])
  ) {
    case "idle":
    case "pending":
      return (
        <PageBase>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Spin size="large" />
          </div>
        </PageBase>
      );
    case "error":
      return (
        <PageBase>
          <PageHeader title="Edit Configuration" />
          <Alert
            type="error"
            message="An error occurred while loading configuration data."
            showIcon
          />
        </PageBase>
      );
    case "complete":
    default:
      if (!selectedConfig.config) {
        return (
          <PageBase>
            <PageHeader title="Edit Configuration" />
            <Alert
              type="error"
              message="The specified configuration does not exist."
              showIcon
            />
          </PageBase>
        );
      }
      break;
  }

  /***********
   * VIEW
   ***********/
  return (
    <ConfigDetailView
      config={id === "0" ? draftConfig : selectedConfig.config}
      actions={actions}
      onConfigGlobalFormSubmit={onConfigGlobalFormSubmit}
      configUpdateRequestStatus={configUpdateRequestStatus}
    />
  );
};
