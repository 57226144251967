import { FC, useEffect, useState } from "react";
import QueryBuilder, {
  Controls,
  Translations,
  Field,
  defaultTranslations,
  formatQuery,
} from "react-querybuilder";
import AntDActionElement from "./AntDActionElement";
import AntDNotToggle from "./AntDNotToggle";
import AntDValueEditor from "./AntDValueEditor";
import AntDValueSelector from "./AntDValueSelector";
import { FactsVar, NameLabelPair, RuleGroupType } from "types/support";
import {
  supportSelectors,
  supportActions,
  useDispatch,
  useSelector,
} from "store";
import { message, Radio, Spin } from "antd";
import { ConfigContactRule } from "types/config";

export type ExpressionBuilderProps = {
  contactRule?: ConfigContactRule;
  onExpressionChange: (contactRule: ConfigContactRule) => void;
};

const controlElements: Partial<Controls> = {
  addGroupAction: AntDActionElement,
  addRuleAction: AntDActionElement,
  combinatorSelector: AntDValueSelector,
  fieldSelector: AntDValueSelector,
  notToggle: AntDNotToggle,
  operatorSelector: AntDValueSelector,
  removeGroupAction: AntDActionElement,
  removeRuleAction: AntDActionElement,
  valueEditor: AntDValueEditor,
};

const translations: Translations = {
  ...defaultTranslations,
  removeRule: {
    label: " ",
    title: "Remove Fact",
  },
  removeGroup: {
    label: " ",
    title: "Remove Sub Rule",
  },
  addRule: {
    label: "+ Fact",
    title: "Add Fact",
  },
  addGroup: {
    label: "+ Sub Rule",
    title: "Add Sub Rule",
  },
};

const baseOperators: NameLabelPair[] = [
  { name: "==", label: "==" },
  { name: "!=", label: "!=" },
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "<=", label: "<=" },
  { name: ">=", label: ">=" },
  { name: "null", label: "is null" },
  { name: "notNull", label: "is not null" },
];

const stringOperators: NameLabelPair[] = [
  ...baseOperators,
  { name: "contains", label: "contains" },
  { name: "beginsWith", label: "begins with" },
  { name: "endsWith", label: "ends with" },
  { name: "doesNotContain", label: "does not contain" },
  { name: "doesNotBeginWith", label: "does not begin with" },
  { name: "doesNotEndWith", label: "does not end with" },
  { name: "in", label: "in" },
  { name: "notIn", label: "not in" },
];

const parseFactsVar = (factsVar: FactsVar): Field => {
  const field: Field = {
    ...factsVar,
    name: factsVar.expVar,
    label: factsVar.expVar,
  };
  switch (factsVar.datatype) {
    case "datetime":
      field.inputType = "date";
      field.operators = baseOperators;
      break;
    case "boolean":
      field.valueEditorType = "radio";
      field.values = [
        { name: "true", label: "True" },
        { name: "false", label: "False" },
      ];
      field.operators = [{ name: "==", label: "is" }];
      break;
    case "integer":
      field.inputType = "number";
      field.operators = baseOperators;
      break;
    case "string":
    default:
      field.operators = stringOperators;
      break;
  }
  return field;
};

const defaultContactRule = { uiExp: "", exp: "", includeFlag: true };

export const ExpressionBuilder: FC<ExpressionBuilderProps> = ({
  contactRule,
  onExpressionChange,
}) => {
  const dispatch = useDispatch();
  const [contactRuleState, setContactRuleState] = useState<ConfigContactRule>(
    contactRule ?? defaultContactRule
  );
  // Putting this in state helps prevent infinite loops when using this as a controlled component
  const [expressionChange] = useState(() => onExpressionChange);

  // Fetch facts vars
  const factsVars = useSelector(supportSelectors.factsList).map((fv) =>
    parseFactsVar(fv)
  );
  const factsVarsStatus = useSelector(supportSelectors.factsStatus);
  useEffect(() => {
    if (factsVarsStatus === "idle") {
      dispatch(supportActions.fetchFactsVariables());
    }
  }, [factsVarsStatus, dispatch]);

  // Event handlers
  useEffect(() => {
    expressionChange(contactRuleState);
  }, [contactRuleState, expressionChange]);

  const onQueryChange = (query: RuleGroupType) => {
    if (!query.rules.length) {
      setContactRuleState(defaultContactRule);
    } else {
      const uiExp = formatQuery(query, "sql");

      if (typeof uiExp === "string") {
        setContactRuleState((cr) => ({ ...cr, uiExp, uiExpStructure: query }));
      } else {
        message.error("An error occurred while formatting an expression.");
      }
    }
  };

  return (
    <div className="expression-builder">
      {factsVarsStatus === "complete" ? (
        <>
          <Radio.Group
            defaultValue={contactRule?.includeFlag ?? true}
            onChange={(e) =>
              setContactRuleState((cr) => ({
                ...cr,
                includeFlag: e.target.value,
              }))
            }
          >
            <Radio value={true}>Include Rule</Radio>
            <Radio value={false}>Exclude Rule</Radio>
          </Radio.Group>
          <QueryBuilder
            query={contactRuleState.uiExpStructure}
            fields={factsVars}
            onQueryChange={(q) => onQueryChange(q)}
            translations={translations}
            controlElements={controlElements}
          />
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};
