import { FC, useEffect, useState } from "react";
import { PageBase } from "components";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
  Button,
  Typography,
  PageHeader,
  Alert,
  Spin,
  message,
} from "antd";

import { RequestStatus } from "types/services";
import FormItem from "antd/lib/form/FormItem";
import {
  channelsActions,
  channelsSelectors,
  useDispatch,
  useSelector,
} from "store";
import { unwrapResult } from "@reduxjs/toolkit";
import { Channel } from "types/channel";

type ChannelDetailForm = {
  name: string;
  id: string;
  digital: boolean;
  outbound: boolean;
  cost: number;
};

export const ChannelDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [channelUpdateRequestStatus, setChannelUpdateRequestStatus] =
    useState<RequestStatus>("idle");

  // Fetch channel
  const selectedChannel = useSelector(channelsSelectors.selectedChannel);
  useEffect(() => {
    if (selectedChannel.status === "idle" || selectedChannel.id !== id) {
      dispatch(channelsActions.fetchChannelById(id));
    }
  }, [selectedChannel, dispatch, id]);

  // Initialize form once data is loaded
  const [channelDetailForm] = Form.useForm<ChannelDetailForm>();

  useEffect(() => {
    if (selectedChannel.status === "complete" && selectedChannel.channel) {
      const chn = selectedChannel.channel;

      channelDetailForm.setFieldsValue({
        name: chn.nbaChannelName,
        id: chn.channelId,
        digital: chn.nbaChannelDigital,
        outbound: chn.nbaChannelOutbound,
        cost: chn.nbaChannelCost,
      });
    }
  }, [channelDetailForm, selectedChannel]);

  const handleChannelDetailSubmit = async (formValues: ChannelDetailForm) => {
    setChannelUpdateRequestStatus("pending");
    try {
      const channelToUpdate: Channel = {
        nbaChannelId: id,
        channelId: "",
        nbaChannelName: "",
        nbaChannelDigital: formValues.digital,
        nbaChannelOutbound: formValues.outbound,
        nbaChannelCost: formValues.cost,
      };

      console.log("channelToUpdate", channelToUpdate);

      unwrapResult(
        await dispatch(channelsActions.updateChannel(channelToUpdate))
      );

      setChannelUpdateRequestStatus("complete");
      // const values = await form.validateFields();
      // console.log("Success:", values);
      message.success("Channel successfully updated.");
      history.push("/channels");
    } catch (e) {
      setChannelUpdateRequestStatus("error");
      // TODO: Could move some of this into the store, e.g. by tracking update error status/type
      // there, which would remove some API implementation details from this component.
      let errMsg = "Channel update failed. Please try again.";

      message.error(errMsg);
    }
  };

  /***********
   * STATUS HANDLING
   ***********/
  // TODO: Status handling is a candidate for refactor - extract to a function, create a reusable component, etc.
  switch (selectedChannel.status) {
    case "idle":
    case "pending":
      return (
        <PageBase>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Spin size="large" />
          </div>
        </PageBase>
      );
    case "error":
      return (
        <PageBase>
          <PageHeader title="Edit Channel" />
          <Alert
            type="error"
            message="An error occurred while loading channel data."
            showIcon
          />
        </PageBase>
      );
    case "complete":
    default:
      if (!selectedChannel.channel) {
        return (
          <PageBase>
            <PageHeader title="Edit Channel" />
            <Alert
              type="error"
              message="The specified channel does not exist."
              showIcon
            />
          </PageBase>
        );
      }
      break;
  }

  return (
    <PageBase>
      <Form
        form={channelDetailForm}
        layout="vertical"
        name="channels"
        onFinish={handleChannelDetailSubmit}
      >
        <PageHeader
          title="Edit Channel"
          extra={
            <FormItem noStyle>
              <Button
                key="1"
                type="primary"
                htmlType="submit"
                loading={channelUpdateRequestStatus === "pending"}
              >
                Save
              </Button>
            </FormItem>
          }
        />

        <Row
          className="hide"
          align="middle"
          style={{
            marginBottom: "20px",
            paddingBottom: "10px",
            borderBottom: "1px solid #d1d7da",
          }}
        >
          <Col className="gutter-row" span={12}>
            <Typography.Title level={2} style={{ marginBottom: "0" }}>
              Edit Channel
            </Typography.Title>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: "right" }}>
            <Button type="primary">Save</Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <Form.Item name="id" label="ID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item name="name" label="Name">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className="gutter-row" span={8}>
            <Form.Item noStyle name="digital" valuePropName="checked">
              <Checkbox>Digital</Checkbox>
            </Form.Item>
            <Form.Item noStyle name="outbound" valuePropName="checked">
              <Checkbox>Outbound</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <Form.Item
              name="cost"
              label="Cost"
              rules={[{ required: true, message: "Cost is required" }]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`}
                style={{ width: "100%" }}
                step="0.01"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </PageBase>
  );
};
