import { RequestStatus, REQUEST_STATUS_OPTIONS } from "types/services";

type Callback = (i: number) => unknown;

export const createArrayFromCallback = (length: number, callback: Callback) => {
  return Array.from({ length }, (v, k) => callback(k));
};

export const getLeastCompleteStatus = (statuses: RequestStatus[]): RequestStatus => {
  for (const status of REQUEST_STATUS_OPTIONS) {
    if (statuses.includes(status)) {
      return status;
    }
  }
  return "error";
};
