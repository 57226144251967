import { configureStore } from "@reduxjs/toolkit";
import { configsReducer } from "./configs/configsSlice";
import { channelsReducer } from "./channels/channelsSlice";
import { actionsReducer } from "./actions/actionsSlice";
import { supportReducer } from "./support/supportSlice";
import {
  TypedUseSelectorHook,
  useSelector as baseUseSelector,
  useDispatch as useBaseDispatch,
} from "react-redux";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["configs/updateConfig/rejected"],
      },
    }),
  reducer: {
    configs: configsReducer,
    channels: channelsReducer,
    actions: actionsReducer,
    support: supportReducer,
  },
});

export { configsActions, configsSelectors } from "./configs/configsSlice";
export { channelsActions, channelsSelectors } from "./channels/channelsSlice";
export { actionsActions, actionsSelectors } from "./actions/actionsSlice";
export { supportActions, supportSelectors } from "./support/supportSlice";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type BaseThunkApi = { dispatch: AppDispatch; state: RootState };

export const useSelector: TypedUseSelectorHook<RootState> = baseUseSelector;
export const useDispatch = () => useBaseDispatch<AppDispatch>();
